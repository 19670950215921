import React, { useState } from "react";
import { MdAdd, MdDeleteForever } from "react-icons/md";

const UpdateAbout = ({ aboutData, handleChange, addNewSkill , deleteSkill}) => {
  // const [aboutData, setAboutData] = useState({
  //   aboutHead: "Welcome To My Site",
  //   aboutDesc:
  //     "I am a website designer from London, with a strong focus in UI/UX design. I love to get new experiences and always learn from my surroundings. I've done more than 285 projects. You can check it through portfolio section on this website. I looking forward to any opportunities and challenges.",
  //   skills: [
  //     {
  //       skill: "Development",
  //       level: "70",
  //     },
  //     {
  //       skill: "Cyber Security",
  //       level: "60",
  //     },
  //     {
  //       skill: "React.js",
  //       level: "80",
  //     },
  //     {
  //       skill: "Next.js",
  //       level: "70",
  //     },
  //     {
  //       skill: "Python",
  //       level: "50",
  //     },
  //     {
  //       skill: "Node.js",
  //       level: "80",
  //     },
  //   ],
  // });

  // const handleChange = (e, index) => {
  //   const { name, value } = e.target;
  //   if (name === "aboutHead" || name === "aboutDesc") {
  //     setAboutData((prevdata) => ({
  //       ...prevdata,
  //       [name]: value,
  //     }));
  //   } else if (name === "skill" || name === "level") {
  //     const updatedSkills = [...aboutData.skills];
  //     updatedSkills[index] = {
  //       ...updatedSkills[index],
  //       [name]: value,
  //     };
  //     setAboutData((prevdata) => ({
  //       ...prevdata,
  //       skills: updatedSkills,
  //     }));
  //   }
  // };
  // const addNewSkill = () => {
  //   setAboutData((prevdata) => ({
  //     ...prevdata,
  //     skills: [
  //       ...prevdata.skills,
  //       { skill: "", level: "" }, // Adding a new skill entry with empty values
  //     ],
  //   }));
  // };

  return (
    <div className="flex">
      {aboutData && (
        <div className="w-2/3 flex flex-col justify-center items-center bg-gray-800">
          {" "}
          <span className="text-blue-400 mb-1 text-sm md:text-base">
            About Me
          </span>
          <h3 className="text-2xl md:text-4xl text-center text-white">
            {aboutData.aboutHead}
          </h3>
          <p className="text-gray-400 mt-9 text-sm md:text-base md:w-7/12 text-center">
            {aboutData.aboutDesc}
          </p>
          <div className="flex flex-wrap mt-20 justify-around w-[100%]">
            {aboutData.skills.map((item, index) => (
              <div key={index} className="w-[100%] md:w-[30%]">
                <div className="flex justify-between mb-4">
                  <p className="text-white text-sm">{item.skill}</p>
                  <p className="text-gray-600 text-sm">{`${item.level}%`}</p>
                </div>
                <div className="w-full bg-black bg-opacity-15 rounded-full h-1  mb-4 dark:bg-gray-700">
                  <div
                    className="bg-gradient-to-r from-[#1e88e9] to-[#72d5f4] h-1 rounded-full "
                    style={{ width: `${item.level}%` }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className=" w-1/3 bg-white shadow-default ">
        <div className="border-b border-stroke py-4 px-6 bg-black ">
          <h3 className="font-medium text-white text-xl ">Section-2</h3>
        </div>
        <form action="#">
          <div className="p-6">
            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Heading
              </label>
              <input
                type="text"
                value={aboutData.aboutHead}
                onChange={handleChange}
                name="aboutHead"
                placeholder="Enter the Heading for Landing Page"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Description
              </label>
              <textarea
                type="text"
                name="aboutDesc"
                onChange={handleChange}
                value={aboutData.aboutDesc}
                placeholder="Enter Description"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            <div className="mb-4">
              {aboutData.skills.map((item, index) => (
                <div
                  key={index}
                  className="flex relative border rounded-lg mb-2 p-2 pt-4 justify-between"
                >
                  <div>
                    <label className="my-2 block text-base font-bold text-gray-800 dark:text-white">
                      {`Skill ${index + 1}`}
                    </label>
                    <input
                      type="text"
                      name="skill"
                      value={item.skill}
                      onChange={(e) => handleChange(e, index)}
                      placeholder="Enter the Heading for Landing Page"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                  </div>
                  <div>
                    <label className="my-2 block text-base font-bold text-gray-800 dark:text-white">
                      Percentage (without Symbol)
                    </label>
                    <input
                      type="number"
                      name="level"
                      value={item.level}
                      onChange={(e) => handleChange(e, index)}
                      placeholder="Enter the Heading for Landing Page"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                  </div>

                  <div className="absolute top-1 right-4 cursor-pointer" onClick={()=>deleteSkill(index)}>
                  <MdDeleteForever  className='text-red-700 text-xl'/>
                  </div>
                </div>
              ))}
              <div className="w-full flex justify-end">
                <button
                  type="button"
                  onClick={addNewSkill}
                  className="flex w-fit mt-3 justify-center items-center gap-2 rounded bg-blue-400 text-base text-white p-3 font-medium hover:bg-blue-600"
                >
                  <MdAdd size={"1.7rem"} />
                  Add New Skill
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateAbout;
