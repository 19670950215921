import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";
import axios from "axios";
import config from "./Config";
const Root = () => {
  const [metaData, setMetaData] = useState({
    title: "Portfolio",
    logo: "logo192.png",
    favicon: "favicon.ico",
    metaDescription: "This is meta Description",
    metaKeyword: "Metakeyword",
  });
 
  const fetchSettings = async () =>{
    try {
      const res = await axios.get(`${config.BASE_URL}/settings`);
      setMetaData(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchSettings();
  }, []);


  return (
    <>
      <Helmet>
        <title>{metaData.title}</title>
        <link rel="icon" href={`${config.IMG_URL}/${metaData.favicon}`} />
        <meta name="description" content={metaData.metaDescription} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <link rel="apple-touch-icon" href={`${config.IMG_URL}/${metaData.logo}`}  />
        {/* Add more meta tags if needed */}
      </Helmet>
      <App />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
