import React, { useState } from "react";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import config from "../../../Config";

const UpdateAbout = ({ aboutData, handleaboutChange }) => {
  return (
    <div className="flex">
      {aboutData && (
        <div className="w-2/3 bg-gray-800 flex flex-col justify-center">
          <h1 className="text-3xl md:text-5xl text-[#25FF00]  text-center">
            About Me
          </h1>
          <div className="flex  mt-32 flex-row-reverse items-center justify-around text-white">
            <div className="md:w-1/3 h-96  flex flex-col items-center md:items-start justify-between text-center md:text-left">
              <h1
                className="text-2xl md:text-3xl"
                dangerouslySetInnerHTML={{ __html: aboutData.aboutHead }}
              ></h1>
              <p className="text-base md:text-base text-slate-200">
                {aboutData.aboutDesc}
              </p>
              <Link to={aboutData.aboutButtonLink} target="_blank">
                {" "}
                <button className="bg-transparent hover:bg-[#25FF00]  text-slate-300 font-semibold text-lg hover:text-white w-fit py-2 px-6 border border-[#25FF00] transition-all duration-300 hover:border-transparent rounded">
                  {aboutData.aboutButtonText}
                </button>
              </Link>
            </div>
            <div className="hidden md:block w-1/3">
              <img
                src={aboutData.aboutImg instanceof File ? URL.createObjectURL(aboutData.aboutImg) : `${config.IMG_URL}/${aboutData.aboutImg}` }
                alt="image"
                className="opacity-80"
              ></img>
            </div>
          </div>
        </div>
      )}
      <div className=" w-1/3 bg-white shadow-default ">
        <div className="border-b border-stroke py-4 px-6 bg-black ">
          <h3 className="font-medium text-white text-xl ">Section-2</h3>
        </div>
        <form action="#">
          <div className="p-6">
            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Heading {"{ <c> Text inside this tag will get color </c> }"}
              </label>
              <input
                type="text"
                value={aboutData.aboutHead}
                onChange={handleaboutChange}
                name="aboutHead"
                placeholder="Enter the Heading for Landing Page"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Description
              </label>
              <textarea
                type="text"
                name="aboutDesc"
                onChange={handleaboutChange}
                value={aboutData.aboutDesc}
                placeholder="Enter Description"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Button Text
              </label>
              <input
                type="text"
                value={aboutData.aboutButtonText}
                onChange={handleaboutChange}
                name="aboutButtonText"
                placeholder="Enter Button Text"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Button Link
              </label>
              <input
                type="text"
                value={aboutData.aboutButtonLink}
                onChange={handleaboutChange}
                name="aboutButtonLink"
                placeholder="Enter Button Link"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Image
              </label>
              <input
                type="file"
                onChange={handleaboutChange}
                name="aboutImg"
                placeholder="Enter Image"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateAbout;
