import React, { useState } from "react";
import { FaCameraRetro } from "react-icons/fa";
import { MdAdd, MdDeleteForever } from "react-icons/md";

const UpdateServices = ({serviceData,handleChange,addNewService,deleteService}) => {
  // const [serviceData, setServiceData] = useState({
  //   serviceHead: "I'm Available For Hire",
  //   services: [
  //     {
  //       title: "Developer",
  //       desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
  //     },
  //     {
  //       title: "Developer",
  //       desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
  //     },
  //     {
  //       title: "Developer",
  //       desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
  //     },
  //     {
  //       title: "Developer",
  //       desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
  //     },
  //     {
  //       title: "Developer",
  //       desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
  //     },
  //     {
  //       title: "Developer",
  //       desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
  //     },
  //   ],
  // });

  // const handleChange = (e,index) =>{
  //   if(e.target.name === 'serviceHead'){
  //     setServiceData((prevData )=> ({...prevData,[e.target.name]:e.target.value}))
  //   }

  //   else if(e.target.name === 'title' || e.target.name === 'desc'){
  //     const updatedData = [...serviceData.services];
  //     updatedData[index] = {...updatedData[index],[e.target.name]:e.target.value};
  //     setServiceData((prevData)=>({...prevData,services:updatedData}))

  //   }
  // }

  // const addNewService = () =>{
  //   setServiceData((prevData) => ({...prevData,services:[...prevData.services,{ title:'',desc:''}]}))
  // }

  return (
    <div className="flex">
      <div
        className="flex w-2/3 bg-gray-800 flex-col items-center justify-center gap-5"
        id="services"
      >
        <span className="text-blue-400 mb-1 text-sm md:text-base">
          My Services
        </span>
        <h3 className="text-2xl md:text-4xl text-center text-white">
          {serviceData.serviceHead}
        </h3>
        <div className="w-full flex flex-wrap gap-3 justify-around p-10">
          {serviceData.services.map((item, index) => (
            <div
              key={index}
              className="service-card w-[30%] p-5 bg-[#555] hover:bg-gradient-to-tr from-[#1e88e9] to-[#72d5f4]  cursor-pointer bg-opacity-50  rounded-md"
            >
              <div className="flex flex-col gap-7 justify-around items-center">
                <p className="text-blue-400">
                  <FaCameraRetro size={"3rem"} />{" "}
                </p>
                <h4 className="text-xl text-white">{item.title}</h4>
                <p className="text-sm md:text-base text-white text-center">
                  {item.desc}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className=" w-1/3 bg-white shadow-default ">
        <div className="border-b border-stroke py-4 px-6 bg-black ">
          <h3 className="font-medium text-xl text-white">
            Section-3
          </h3>
        </div>
        <form action="#">
          <div className="p-6">
            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Heading
              </label>
              <input
                type="text"
                value={serviceData.serviceHead}
                onChange={handleChange}
                name="serviceHead"
                placeholder="Enter the Heading for Landing Page"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            <div className="mb-4">
              {serviceData.services.map((item, index) => (
                <div key={index} className="flex gap-2 border rounded-lg p-2 pt-2 relative mb-2 justify-between">
                  <div className="w-1/3">
                    <label className="my-2 block text-base font-bold text-gray-800 dark:text-white">
                      {`Service ${index + 1}`}
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={item.title}
                      onChange={(e) => handleChange(e, index)}
                      placeholder="Enter the Heading for Landing Page"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                  </div>
                  <div className="w-2/3">
                    <label className="my-2 block text-base font-bold text-gray-800 dark:text-white">
                      Description
                    </label>
                    <textarea
                      type="text"
                      name="desc"
                      value={item.desc}
                      onChange={(e) => handleChange(e, index)}
                      placeholder="Enter the Heading for Landing Page"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                  </div>
                  <div className="absolute top-1 right-4 cursor-pointer" onClick={()=>deleteService(index)}>
                  <MdDeleteForever  className='text-red-700 text-xl'/>
                  </div>
                </div>
              ))}
              <div className="w-full flex justify-end">
              <button
                type="button"
                onClick={addNewService}
                className="flex w-fit items-center mt-3 justify-center gap-2 rounded bg-blue-400 text-base text-white p-3 font-medium hover:bg-blue-600"
              >
                <MdAdd size={'1.7rem'} />
                Add New Service
              </button>
            </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateServices;
