import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../Config";
import { toast } from "react-toastify";

const FooterSetting = () => {
  const [data, setData] = useState({
    twitter:"",
    linkedin:"",
    facebook:"",
    instagram:"",
    mail:"",
  });
  const token = localStorage.getItem('token');

  const fetchFooterData = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/footer`);
     {res.data && setData(res.data);}
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpload = async () => {
    try {
      const res = await axios.put(`${config.BASE_URL}/footer`, data, {
        headers: {
          "x-auth-token": `Bearer ${token}`,
        },
      });

      toast.success("Footer Updated!");
      fetchFooterData();
    } catch (e) {
        toast.error("Token Expire,Login Again!")
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchFooterData();
  },[]);

  return (
    <div className=" w-full bg-white shadow-default mb-16">
      <div className="border-b border-stroke bg-black py-4 px-6 ">
        <h3 className="font-medium text-white  text-xl">Footer Settings</h3>
      </div>
    
        <form action="#" className="flex flex-col items-end">
          <div className="p-6 flex w-full flex-wrap gap-5 justify-between">
            <div className="mb-4 w-[47%]">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Twitter
              </label>
              <input
                type="text"
                onChange={handleChange}
                value={data.twitter}
                name="twitter"
                placeholder="Enter Twitter URL"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <div className="mb-4 w-[47%]">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                LinkedIn
              </label>
              <input
                type="text"
                name="linkedin"
                value={data.linkedin}
                onChange={handleChange}
                placeholder="Enter Linkedin URL"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            <div className="mb-4 w-[47%]">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Instagram
              </label>
              <input
                type="text"
                onChange={handleChange}
                value={data.instagram}
                name="instagram"
                placeholder="Enter Instagram URL"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <div className="mb-4 w-[47%]">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Facebook
              </label>
              <input
                type="text"
                name="facebook"
                value={data.facebook}
                onChange={handleChange}
                placeholder="Enter Facebook URL"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            <div className="mb-4 w-[47%]">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Mail
              </label>
              <input
                type="text"
                onChange={handleChange}
                value={data.mail}
                name="mail"
                placeholder="Enter Your Mail-Id"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
          </div>
          <button
            type="button"
            onClick={handleUpload}
            className="flex w-fit h-fit mr-7 px-7 justify-center rounded bg-lime-400 text-base text-white p-3 font-medium hover:bg-lime-600"
          >
            Update
          </button>
        </form>
     
    </div>
  );
};

export default FooterSetting;
