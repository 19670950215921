import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../Config";

const BlogPage = ({ placeholder }) => {
  const { id } = useParams();

  const [dates,setDates] = useState("");
  const [data, setData] = useState(null);

  const fetchData = async () => {
    const res = await axios.get(`${config.BASE_URL}/blogs/${id}`);
    setData(res.data);
    const date = new Date(res.data.date);
const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
setDates(formattedDate);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bg-white w-full min-h-screen text-black">
      {data && (
        <div className="w-[60%] ml-[20%] py-16 px-[5%]">
          <div>
            <h1 className="text-5xl mb-3">{data.title}</h1>
            <span className="text-sm">Published-Date: {dates}</span>
          </div>
          <div className="w-full h-96 my-7">
            <img
              src={`${config.IMG_URL}/${data.image}`}
              alt="blog-image"
              className="w-full h-full"
            />
          </div>

          <div
            className="mt-14"
            dangerouslySetInnerHTML={{ __html: data.richtext }}
          />
        </div>
      )}
    </div>
  );
};

export default BlogPage;
