import React, { useEffect, useState } from "react";

const UpdateHero = ({ data, handleChange }) => {
  // const imgURl =
  //   "https://plus.unsplash.com/premium_photo-1674669009418-2643aa58b11b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  // const [data, setData] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch("/Data.json");
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const data = await response.json();
  //       setData(data.Hero);
  //     } catch (error) {
  //       console.error(
  //         "There has been a problem with your fetch operation:",
  //         error
  //       );
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const handleChange = (e) => {
  //   setData((prevdata) => ({ ...prevdata, [e.target.name]: e.target.value }));
  // };

  return (
    <div className="flex ">
      {data && (
        <div className="w-2/3  flex flex-row items-center   bg-gray-800 text-white">
          <div className="w-1/2 h-[40%] ml-56 flex gap-5 flex-col justify-between">
            <h1 className="text-4xl">
              {data.head}{" "}
              <span className="text-blue-400">{`{${data.roles}}`}</span>{" "}
            </h1>
            <button className="bg-transparent hover:bg-blue-400 text-slate-300 font-semibold text-lg hover:text-white  w-fit py-2 px-6 border border-slate-500 hover:border-transparent rounded">
              {data.buttonText}
            </button>
          </div>
        </div>
      )}
      {data && (
        <div className=" w-1/3 bg-white shadow-default">
          <div className="border-b border-stroke bg-black py-4 px-6 ">
            <h3 className="font-medium text-white  text-xl">Section-1</h3>
          </div>
          <form action="#">
            <div className="p-6">
              <div className="mb-4">
                <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                  Heading
                </label>
                <input
                  type="text"
                  onChange={handleChange}
                  value={data.head}
                  name="head"
                  placeholder="Enter the Heading for Landing Page"
                  className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
              </div>

              <div className="mb-4">
                <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                  Roles
                </label>
                <input
                  type="text"
                  name="roles"
                  // value={data.roles}
                  onChange={handleChange}
                  placeholder="Enter Roles (Comma Seperated)"
                  className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
              </div>
              <div className="mb-4">
                <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                  Button Text
                </label>
                <input
                  type="text"
                  name="buttonText"
                  value={data.buttonText}
                  onChange={handleChange}
                  placeholder="EnterText for Button"
                  className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
              </div>
              <div className="mb-4">
                <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                  Button Link
                </label>
                <input
                  type="text"
                  name="link"
                  value={data.buttonLink}
                  onChange={handleChange}
                  placeholder="Enter the Heading for Landing Page"
                  className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default UpdateHero;
