import React from "react";
import { Link } from "react-router-dom";
import config from "../../../Config";

const About = ({about}) => {
  
  return (
    <section className="w-[80%] ml-[10%]  mb-44" id="about">
      <h1 className="text-3xl md:text-5xl text-[#25FF00]  text-center">
        About Me
      </h1>
      <div className="flex  mt-32 flex-row-reverse items-center justify-around text-white">
        <div className="md:w-1/3 h-96  flex flex-col items-center md:items-start justify-between text-center md:text-left">
          <h1 className="text-2xl md:text-4xl">
            Hi! Iam a{" "}
            <span className="text-[#25FF00] ">  Cyber Security Expert </span>{" "}
            with 3+ years of experience{" "}
          </h1>
          <p className="text-base md:text-lg text-slate-200">
            {about.aboutDesc}
          </p>
          <Link to={about.aboutButtonlink} target="_blank">
          <button className="bg-transparent hover:bg-[#25FF00]  text-slate-300 font-semibold text-lg hover:text-white w-fit py-2 px-6 border border-[#25FF00] transition-all duration-300 hover:border-transparent rounded">
            {about.aboutButtonText}
          </button>
          </Link>
        </div>
        <div className="hidden md:block w-1/3">
          <img src={`${config.IMG_URL}/${about.aboutImg}`} alt="image" className="opacity-80"></img>
        </div>
      </div>
    </section>
  );
};

export default About;
