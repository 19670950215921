import React, { useEffect, useState } from "react";
import { MdAdd, MdDeleteForever } from "react-icons/md";

const UpdateExperience = ({xpData,handleChange,addNew,deleteExperience}) => {
  // const [xpData, setXpData] = useState([
  //     {
  //       id: 1,
  //       company: "Software Sathi",
  //       startYear: 2024,
  //       endYear: 0,
  //       role: "Software Engineer",
  //       desc: "asdkjbhgfi hasijjfhgi hsaiufg hgshgd gduigas d suia dgiuaghsd usg agd ys dgsg dsa dgsa dga fgsa fg adhag ikqawq ls h fkalns k asdkjbhgfi hasijjfhgi hsaiufg hgshgd gduigas d suia dgiuaghsd usg agd ys dgsg dsa dgsa dga fgsa fg adhag ikqawq ls h fkalns k",
  //       class:"md:row-span-2"
  //     },
  //     {
  //       id: 2,
  //       company: "Email Miners",
  //       startYear: 2023,
  //       endYear:2024,
  //       role: "Software Engineer",
  //       desc: "asdkjbhgfi hasijjfhgi hsaiufg hgshgd gduigas d suia dgiuaghsd usg agd ys dgsg dsa dgsa dga fgsa fg adhag ikqawq ls h fkalns k",
  //       class: "md:col-span-2"
  //     },
  //     {
  //       id: 3,
  //       company: "Microsoft",
  //       startYear: 2022,
  //       endYear:2023,
  //       role: "Software Engineer",
  //       desc: "asdkjbhgfi hasijjfhgi hsaiufg hgshgd gduigas d suia dgiuaghsd usg agd ys dgsg dsa dgsa dga fgsa fg adhag ikqawq ls h fkalns k",
  //       class: "md:col-span-1"
  //     },
  //     {
  //       id: 4,
  //       company: "Microsoft",
  //       startYear: 2020,
  //       endYear:2022,
  //       role: "Software Engineer",
  //       desc: "asdkjbhgfi hasijjfhgi hsaiufg hgshgd gduigas d suia dgiuaghsd usg agd ys dgsg dsa dgsa dga fgsa fg adhag ikqawq ls h fkalns k",
  //       class:"md:row-span-1"
  //     },
  //   ]);

  // const handleChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedXp = xpData.map((item, idx) =>
  //     idx === index ? { ...item, [name]: value } : item
  //   );
  //   setXpData(updatedXp);
  // };

  // const addXp = () => {
  //   setXpData((prevData) => [
  //     {
  //       company: "",
  //       desc: "",
  //       role:"",
  //       startYear: 0,
  //       endYear: 0,
  //       class:"col-span-3",
  //     },
  //     ...prevData,
  //   ]);
  // };
  const getclassname = (index) =>{
    if(index === 0){
      return "md:row-span-2";
    }
    if(index === 1){
      return "md: col-span-2";
    }
    if(index === 2){
      return "md:col-span-1";
    }
    if(index === 3){
      return "md:row-span-1";
    }
  }

  return (
    <div className="flex w-full bg-white">
      <div
        className="flex w-2/3 bg-gray-800 flex-col items-center justify-center p-5"
        id="services"
      >
        <h1 className="text-3xl md:text-5xl text-[#25FF00]  text-center">
        Experience
      </h1>
      <div className=" mt-32">        
        <div className="grid md:grid-cols-3 md:grid-row-4 gap-4">
          {xpData.map((item,index)=>(
            <div key={index} className={`${getclassname(index)}  bg-black border border-gray-700 bg-opacity-10 rounded-md backdrop-blur-sm cursor-pointer hover:shadow-md transition-color duration-300 hover:shadow-[#25FF00]`}>
              <div className="flex flex-col h-full justify-around p-4">
              <div>
                    <h6 className="text-3xl text-green-400">{item.company}</h6>
                    <span className="text-slate-300 text-xs">{item.startYear}{" - "} {item.endYear == 0 ? 'Present' : item.endYear} </span>
                  </div>
                  <p className="text-slate-100 text-xl"> {item.role} </p>
                  <p className="text-base text-slate-300"> {item.desc} </p>
                
              </div>

            </div>
          ))}
        </div>

      </div>
      </div>


      <div className=" w-1/3 h-auto bg-white shadow-default ">
        <div className="border-b bg-black border-stroke py-4 px-6 ">
          <h3 className="font-medium  text-xl text-white">Section-5</h3>
        </div>
        <form action="#">
          <div className="p-6">
            {xpData.map((item, index) => (
              <div key={index} className="p-2  pt-4 relative border rounded-lg mb-2">
                {/* <h6>{`Experience-${index + 1}`}</h6> */}
                <div className="mb-1 flex gap-2">
                  <div className="w-1/2">
                    <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                      Company
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleChange(e, index)}
                      value={item.company}
                      name="company"
                      placeholder="Enter Company Name"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                      Role
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleChange(e, index)}
                      value={item.role}
                      name="role"
                      placeholder="Enter Role"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                  </div>
                  
                </div>

                <div className="flex gap-2">
                <div className="w-1/2">
                    <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                      Starting Year
                    </label>
                    <input
                      type="number"
                      value={item.startYear}
                      onChange={(e) => handleChange(e, index)}
                      name="startYear"
                      placeholder="Enter Company Name"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                      End Year (0 for present)
                    </label>
                    <input
                      type="number"
                      onChange={(e) => handleChange(e, index)}
                      value={item.endYear}
                      name="endYear"
                      placeholder="Enter Company Name"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="desc"
                    value={item.desc}
                    onChange={(e) => handleChange(e, index)}
                    placeholder="Enter Description"
                    className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                </div>
                <div className="absolute top-1 right-4 cursor-pointer" onClick={()=>deleteExperience(index)}>
                  <MdDeleteForever  className='text-red-700 text-xl'/>
                  </div>
              </div>
            ))}
            <div className="w-full flex justify-end">
            <button
                type="button"
                onClick={addNew}
                className="flex w-fit gap-2 items-center mt-3 mb-2 justify-center rounded bg-blue-400 text-base text-white p-3 font-medium hover:bg-blue-600"
              >
                <MdAdd size={'1.7rem'} />
                Add Experience
              </button>
              </div>
           
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateExperience;
