import React from "react";
import { FaUserFriends } from "react-icons/fa";

const Dashboard = () => {
  return (
    <div className="p-2">
      <h2 className="font-bold text-gray-900 m-5">Dashboard</h2>
      <div className="flex flex-wrap justify-around mt-16">
        <div className="py-5 px-20 w-fit  rounded-lg bg-slate-100 shadow-lg flex flex-col items-center">
        <FaUserFriends size={'3rem'}  className="text-blue-300"/>
            <h4 className="text-gray-700"><b>User Count</b></h4>
            <p className="text-gray-500">0</p>
        </div>
        <div className="py-5 px-20 w-fit  rounded-lg bg-slate-100 shadow-lg flex flex-col items-center">
        <FaUserFriends size={'3rem'}  className="text-violet-300"/>
            <h4 className="text-gray-700"><b>Total Messages</b></h4>
            <p className="text-gray-500">0</p>
        </div>
        <div className="py-5 px-20 w-fit  rounded-lg bg-slate-100 shadow-lg flex flex-col items-center">
        <FaUserFriends size={'3rem'}  className="text-red-300"/>
            <h4 className="text-gray-700"><b>No. Templates</b></h4>
            <p className="text-gray-500">0</p>
        </div>
        <div className="py-5 px-20 w-fit  rounded-lg bg-slate-100 shadow-lg flex flex-col items-center">
        <FaUserFriends size={'3rem'}  className="text-lime-300"/>
            <h4 className="text-gray-700"><b>Blogs Count</b></h4>
            <p className="text-gray-500">0</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
