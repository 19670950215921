import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../Config";

const Blogs = ({ blogHead }) => {
  const [blogList, setBlogList] = useState([]);
  const fetchBlogs = async () => {
    const res = await axios.get(`${config.BASE_URL}/blogs`);
    setBlogList(res.data);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <section
      className="w-[60%] ml-[20%] flex flex-col items-center mb-28 md:mb-44"
      id="blogs"
    >
      <span data-aos="fade-up" className="text-blue-400 mb-1 text-sm md:text-base">Blogs</span>
      <h3 data-aos="fade-up" className="text-2xl md:text-4xl text-center text-white">
        {blogHead}
      </h3>
      <div data-aos="zoom-out" className="flex flex-wrap gap-5 justify-between mt-20 md:mt-32 w-full ">
        {blogList.map((item, index) => (
          <div
            key={index}
            className="flex flex-col hover:cursor-pointer overflow-clip  justify-between w-full md:w-[31%] max-h-[500px] bg-slate-200 bg-opacity-10 rounded-md backdrop-blur-sm shadow-sm shadow-gray-700"
          >
            <Link target="_blank" key={item._id} to={`/blog-page/${item._id}`}>
              <img
                src={`${config.IMG_URL}/${item.image}`}
                className="w-full h-[150px] md:h-[200px]"
                alt="blog-image"
              />
              <div className="px-6 mt-2">
                <span className="text-blue-400 text-xs md:text-base">
                  {item.date.substring(0,10)}{" "}
                </span>
                <h6 className="text-xl md:text-3xl  text-white">
                  {item.title}
                </h6>
              </div>
              <p className="text-xs md:text-base  text-slate-400 px-6 mb-3">
                {" "}
                {item.description}{" "}
              </p>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Blogs;
