import React, { useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { FaEye } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import config from "../../../Config";
import { toast } from "react-toastify";

const CreateBlog = ({ placeholder }) => {
  const token = localStorage.getItem("token");
  const editor = useRef(null);
  const [blogImage, setBlogImage] = useState(null);
  const [show, setShow] = useState(false);
  const [blogData, setBlogData] = useState({
    title: "",
    image: "",
    richtext: "",
    description: "",
    date: new Date(),
  });
  const handleChange = (e) => {
    try {
      const { name, value, files } = e.target;
      if (files && name === "image") {
        const file = files[0];
        const imgURl = URL.createObjectURL(file);
        setBlogImage(file);
        setBlogData((prevData) => ({
          ...prevData,
          [name]: imgURl,
        }));
      } else {
        setBlogData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const configuration = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: placeholder || "Start typing...",
      height: "70vh",
    }),
    [placeholder]
  );

  const handleBlur = (newContent) => {
    setBlogData((prevState) => ({
      ...prevState,
      richtext: newContent,
    }));
  };

  const handleRichChange = (newContent) => {
    setBlogData((prevState) => ({
      ...prevState,
      richtext: newContent,
    }));
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("title", blogData.title);
      formData.append("image", blogImage);
      formData.append("description", blogData.description);
      formData.append("richtext", blogData.richtext);
      formData.append("date", new Date());
      const res = await axios.post(`${config.BASE_URL}/blogs`, formData, {
        headers: { "x-auth-token": `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("New Blog Created");
        setBlogData({
          title: "",
          image: "",
          richtext: "",
          description: "",
          date: new Date(),
        });
        setBlogImage(null);
      }
    } catch (error) {}
  };
  const handleError = (event) => {
    event.target.src = 'no-photo.png'; // Set fallback image
  };

  return (
    <div className="bg-white w-full min-h-screen py-4 text-black">
      <div className="w-[96%] mx-[2%] h-full">
        <div className="flex justify-between">
          <h2>Create Blog</h2>
          <div
            onClick={() => setShow(!show)}
            className="flex border items-center py-1 px-2 rounded-lg text-white gap-3 text-base border-gray-600 bg-black cursor-pointer"
          >
            <FaEye /> Preview{" "}
          </div>
        </div>
        <form action="#" className="mb-9">
          <div className="p-6">
            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Title
              </label>
              <input
                type="text"
                value={blogData.title}
                onChange={handleChange}
                name="title"
                placeholder="Enter Title for your Blog"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Image
              </label>
              <input
                type="file"
                onChange={handleChange}
                name="image"
                placeholder="Upload Image"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Create Your Blog
              </label>

              <JoditEditor
                ref={editor}
                value={blogData.richtext}
                config={configuration}
                tabIndex={1}
                onBlur={handleBlur}
                onChange={handleRichChange}
              />
            </div>

            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Description
              </label>
              <textarea
                type="text"
                value={blogData.description}
                onChange={handleChange}
                name="description"
                placeholder="Enter Description of Your Blog"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <button
              type="button"
              onClick={handleUpdate}
              className="flex absolute mt-4 w-fit px-7 right-16 justify-center rounded bg-lime-400 text-base text-white p-3 font-medium hover:bg-lime-600"
            >
              Create
            </button>
          </div>
        </form>
      </div>

      {/* ------------------------Modal--------------------------------------> */}
      <div
        id="Modal"
        tabIndex={"-1"}
        onClick={() => setShow(false)}
        aria-hidden="true"
        className={`${
          show ? " " : " hidden"
        } backdrop-blur-sm overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center p-5  md:inset-0 h-full max-h-full`}
      >
        <div
          onClick={(event) => event.stopPropagation()}
          className="w-[60%] ml-[20%] p-3 pt-9 pb-10 border h-full relative bg-white overflow-y-auto"
        >
          <h1 className="mb-4">{blogData.title}</h1>
          <img
            src={blogData.image}
            alt="blogImage"
            className="w-full h-[25rem]"
            onError={handleError}
          />
          <div
            className="mt-10"
            dangerouslySetInnerHTML={{ __html: blogData.richtext }}
          />
          <div
            onClick={() => setShow(false)}
            className="absolute top-3 text-3xl cursor-pointer right-4"
          >
            <RxCross1 />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBlog;
