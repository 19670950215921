import React, { useState, useEffect } from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [open,setOpen] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 30) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav >
      <div className=' sm:hidden z-40 fixed top-6 left-4 text-white' onClick={()=>setOpen(!open)}>
      <RxHamburgerMenu size={'1.5rem'}/>
      </div>
      <div className={`w-screen h-screen flex justify-center  bg-black text-white  ${open ? 'left-0 fixed top-0 transition-all duration-700 delay-300' : ' absolute -left-[200%] transition-all duration-700 delay-300'} z-30`}>
      <ul className='list-none flex flex-col gap-10 mt-28 cursor-pointer text-3xl'>
                <li><Link to='about' spy={true} offset={-100} onClick={()=>setOpen(!open)} >About</Link></li>

                <li><Link to='services' spy={true} offset={-100} onClick={()=>setOpen(!open)} >Services</Link></li>

                <li><Link to='portfolio' spy={true} offset={-100} onClick={()=>setOpen(!open)} >Portfolio</Link></li>

                <li><Link to='experience' spy={true} offset={-100} onClick={()=>setOpen(!open)} >Experience</Link></li>

                <li><Link to='blogs' spy={true} offset={-100} onClick={()=>setOpen(!open)} >Blogs</Link></li>
                
                <li><Link to='contact' spy={true} offset={-200} onClick={()=>setOpen(!open)} >Contact</Link></li>
            </ul>
      </div>



     <div className={`w-full hidden sm:block z-20 p-3 ${isSticky ? 'fixed top-0 bg-black bg-opacity-80 backdrop-blur-sm shadow-md transition-all duration-400' : 'absolute top-10 transition-all duration-400'}`}>
      <div className='ml-[20%] w-[60%] hidden sm:flex justify-between items-center text-white text-base '>
        <div className=' h-8 cursor-pointer'>
          <Link to='home'> <img src='https://www.madebydesignesia.com/themes/profession/images/logo-light.png' className='w-full h-full' /> </Link> 
        </div>
        <div>
            <ul className='list-none flex gap-5 cursor-pointer'>
                <li><Link to='about' spy={true} offset={-100} className='relative w-fit block after:block after:content-[""] after:absolute after:h-[3px] after:bg-blue-400 after:w-full after:scale-x-0 after:transition after:duration-300 after:origin-left' activeClass=' after:scale-x-100 text-blue-400'>About</Link></li>

                <li><Link to='services' spy={true} offset={-100} className='relative w-fit block after:block after:content-[""] after:absolute after:h-[3px] after:bg-blue-400 after:w-full after:scale-x-0 after:transition after:duration-300 after:origin-left' activeClass=' after:scale-x-100 text-blue-400'>Services</Link></li>

                <li><Link to='portfolio' spy={true} offset={-100} className='relative w-fit block after:block after:content-[""] after:absolute after:h-[3px] after:bg-blue-400 after:w-full after:scale-x-0 after:transition after:duration-300 after:origin-left' activeClass=' after:scale-x-100 text-blue-400'>Portfolio</Link></li>

                <li><Link to='experience' spy={true} offset={-100} className='relative w-fit block after:block after:content-[""] after:absolute after:h-[3px] after:bg-blue-400 after:w-full after:scale-x-0 after:transition after:duration-300 after:origin-left' activeClass=' after:scale-x-100 text-blue-400'>Experience</Link></li>

                <li><Link to='blogs' spy={true} offset={-100} className='relative w-fit block after:block after:content-[""] after:absolute after:h-[3px] after:bg-blue-400 after:w-full after:scale-x-0 after:transition after:duration-300 after:origin-left' activeClass=' after:scale-x-100 text-blue-400'>Blogs</Link></li>
                
                <li><Link to='contact' spy={true} offset={-200} className='relative w-fit block after:block after:content-[""] after:absolute after:h-[3px] after:bg-blue-400 after:w-full after:scale-x-0 after:transition after:duration-300 after:origin-left' activeClass=' after:scale-x-100 text-blue-400'>Contact</Link></li>
            </ul>
        </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
