import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PrivateLayout from "./Components/Admin/PrivateLayout";
import Settings from "./Components/Admin/Setting/Settings";
import CreateBlog from "./Components/Admin/Blogs/CreateBlog";
import Dashboard from "./Components/Admin/Dashboard";
import ContactInfo from "./Components/Admin/ContactInfo";
import Login from "./Components/Admin/Login";
import UpdateLandingPage from "./Components/Admin/UpdateLandingPage";
import Home2 from "./Components/Template2/UI/Home";
import Home from "./Components/Template1/UI/Home";
import { useEffect } from "react";
import Aos from "aos";
import { ToastContainer } from "react-toastify";
import BlogPage from "./Components/Admin/Blogs/BlogPage";
import BlogList from "./Components/Admin/Blogs/BlogList";
import EditBlog from "./Components/Admin/Blogs/EditBlog";


function App() {
  useEffect(() => {
    Aos.init({
      duration: 800, // Duration of animation
      easing: "ease-in-out", // Easing function
      once: true, // Animation should only happen once
    });
  }, []);
  return (
    <Router>
      <ToastContainer position="top-center" autoClose={5000}/>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route exact path="/" element={<Home />} />
        <Route element={<PrivateLayout />}>
          <Route path="/update-landing-page" element={<UpdateLandingPage />} />
          <Route path="create-blog" element={<CreateBlog />} />
          <Route path="blog-list" element={<BlogList/>} />
          <Route path="/edit-blog/:id" element={<EditBlog />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="forms-submitted" element={<ContactInfo />} />
        </Route>
        <Route path="template-2" element={<Home2 />} />
        <Route path="blog-page/:id" element={<BlogPage />} />
      </Routes>
    </Router>
  );
}

export default App;
