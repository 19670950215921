import React from "react";
import { FaCameraRetro } from "react-icons/fa";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { MdOutlineHomeRepairService } from "react-icons/md";

const Services = ({services}) => {
  const serviceData = [
    {
      title: "Developer",
      desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
    },
    {
      title: "Developer",
      desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
    },
    {
      title: "Developer",
      desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
    },
    {
      title: "Developer",
      desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
    },
    {
      title: "Developer",
      desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
    },
    {
      title: "Developer",
      desc: "asbgg agf  ekf ashkh hshah uh hs ajfhya hef nall afiask lfafsnmf hf jafu ehas asif ahkhef  ub aj jash  ahi",
    },
  ];

  const option = { loop: true };

  const [emblaRef, emblaApi] = useEmblaCarousel(option, [
    Autoplay({ playOnInit: true, delay: 3000, stopOnInteraction: false }),
  ]);

  return (
    <section
      className="w-[60%] ml-[20%] flex flex-col items-center mb-28 md:mb-44 overflow-hidden"
      id="services"
    >
      <span data-aos="fade-up" className="text-blue-400 mb-1 text-sm md:text-base">
        My Services
      </span>
      <h3 data-aos="fade-up" className="text-2xl md:text-4xl text-center text-white">
        {services.serviceHead}
      </h3>
      <div data-aos="zoom-out" className="w-[100%]">
        <div className="embla_viewport" ref={emblaRef}>
          <div className="embla__container ">
            {services.services.map((item, index) => (
              <div
                key={index}
                className="service-card ml-[3%] flex flex-wrap mt-20 justify-around min-w-full md:min-w-[30%] p-8 bg-[#555] hover:bg-gradient-to-tr from-[#1e88e9] to-[#72d5f4]  cursor-pointer bg-opacity-50  rounded-md"
              >
                <div className="flex flex-col gap-7 justify-around items-center">
                  <p className="text-blue-400">
                    <MdOutlineHomeRepairService size={"3rem"} />{" "}
                  </p>
                  <h4 className="text-xl text-white">{item.title}</h4>
                  <p className="text-sm md:text-base text-white text-center">
                    {item.desc}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
