import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdDeleteForever } from "react-icons/md";
import { TbMailUp } from "react-icons/tb";
import config from "../../Config";

const ContactInfo = () => {
  const [data, setData] = useState(null);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Mail",
      selector: (row) => row.mail,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.description,
    },
  ];

  const fetchData = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/contact`);
      setData(res.data.data);
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(()=>{
    fetchData();
  })

  return (
    <div className="p-2">
      <h2 className="font-bold text-gray-900 m-5">Forms Submitted</h2>
      <div className="w-full p-4 ">
     { data &&  <DataTable columns={columns} data={data} pagination highlightOnHover />}
      </div>
    </div>
  );
};

export default ContactInfo;
