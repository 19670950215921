import React from 'react'

const About = ({about}) => {

const skills = [
    {
        skill:'Development',
        level:'70'
    },
    {
        skill:'Cyber Security',
        level:'60'
    },
    {
        skill:'React.js',
        level:'80'
    },
    {
        skill:'Next.js',
        level:'70'
    },
    {
        skill:'Python',
        level:'50'
    },
    {
        skill:'Node.js',
        level:'80'
    }
]



    return (
        <section className='w-[60%] ml-[20%] flex flex-col items-center mb-28 md:mb-44' id='about'>
            <span data-aos="fade-up" className='text-blue-400 mb-1 text-sm md:text-base'>About Me</span>
            <h3 data-aos="fade-up" className='text-2xl md:text-4xl text-center text-white'>{about.aboutHead}</h3>
            <p data-aos="fade-up" className='text-gray-400 mt-9 text-sm md:text-base md:w-7/12 text-center'>{about.aboutDesc}</p>
            <div data-aos="zoom-in" className='flex flex-wrap mt-20 justify-around w-[100%]'>
                {about.skills.map((item,index)=>(
                <div className='w-[100%] md:w-[30%]'>
                    <div className='flex justify-between mb-4'>
                    <p className='text-white text-sm'>{item.skill}</p>
                    <p className='text-gray-600 text-sm'>{`${item.level}%`}</p>
                    </div>
                    <div className="w-full bg-black bg-opacity-15 rounded-full h-1  mb-4 dark:bg-gray-700">
                        <div className="bg-gradient-to-r from-[#1e88e9] to-[#72d5f4] h-1 rounded-full " style={{ width: `${item.level}%` }}></div>
                    </div>
                </div>
                ))}

            </div>
        </section>
    )
}

export default About