import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../Config";
import { Link, useNavigate } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";

const BlogList = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [blogList, setBlogList] = useState([]);
  const fetchBlogs = async () => {
    const res = await axios.get(`${config.BASE_URL}/blogs`);
    setBlogList(res.data);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleEditClick = ( id, data ) => {
    console.log(id);
    navigate(`/edit-blog/${id}`, { state: { data } });
  };

  const handleDeleteClick = async (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure you want to delete?");
      if (userConfirmed) {
        const res = await axios.delete(`${config.BASE_URL}/blogs/${id}`, {
          headers: { "x-auth-token": `Bearer ${token}` },
        });
        if (res.status === 200) {
          fetchBlogs();
          toast.success("Blog Deleted Succesfully!");
        }
      } else {
        console.log("Delete action canceled");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className="p-5">
      <h1>Blogs List</h1>
      <div className="flex flex-wrap gap-5  mt-10 md:mt-16 w-full ">
        {blogList.map((item, index) => (
          <div
            key={index}
            className="flex flex-col relative hover:cursor-pointer overflow-clip  justify-around w-full md:w-[22%] max-h-[500px] bg-black bg-opacity-10 rounded-md backdrop-blur-sm shadow-sm shadow-gray-700"
          >
            <Link target="_blank" key={item._id} to={`/blog-page/${item._id}`}>
              <img
                src={`${config.IMG_URL}/${item.image}`}
                className="w-full h-[100px]  opacity-90  md:h-[150px]"
                alt="anything"
              />
              <div className="px-6 mt-2">
                <span className="text-blue-400 text-xs md:text-base">
                  {item.tag}{" "}
                </span>
                <h6 className="text-xl md:text-2xl  text-black">
                  {item.title}
                </h6>
              </div>
              <p className="text-xs md:text-base  text-slate-900 px-6 mb-3">
                {" "}
                {item.description}{" "}
              </p>
            </Link>
            <div
              onClick={() => handleDeleteClick(item._id)}
              className="absolute top-3 right-3 cursor-pointer text-red-400 hover:text-red-600"
            >
              <MdDeleteForever size={"1.5rem"} />
            </div>
            <div
              onClick={() => handleEditClick(item._id, item)}
              className="absolute top-3 right-12 cursor-pointer text-blue-300 hover:text-blue-600"
            >
              <FaRegEdit size={"1.5rem"} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
