import React, { useEffect, useRef, useState } from "react";
import { FaUserAstronaut } from "react-icons/fa";
import { IoLogOutOutline, IoSettingsOutline } from "react-icons/io5";
import { RiContactsBook3Line, RiDashboard2Line } from "react-icons/ri";
import { TbBrandBlogger, TbHomeEdit } from "react-icons/tb";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CiBoxList } from "react-icons/ci";

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  const logout = () =>{
    localStorage.removeItem('token');
    navigate('/');
  }

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 text-base text-white top-0 z-9999 flex h-screen w-72 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="flex items-center justify-between gap-2 px-6 py-5 lg:py-6">
        {/* <NavLink to="/">
                    <img src={Logo} alt="Logo" />
                </NavLink> */}

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
          <div className="flex justify-between flex-col h-full">

      <div className=" flex flex-col overflow-y-auto duration-300 ease-linear">
        <div className="flex justify-between items-center mx-6">
          <FaUserAstronaut size={"3rem"} />
          <h3>Admin Panel</h3>
        </div>
        <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
          <div>
            <ul className="mb-6 flex flex-col gap-5">
              <li>
                <NavLink
                  to="/dashboard"
                  className={`group relative flex items-center gap-2 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                    (pathname === "/dashboard" ||
                      pathname.includes("dashboard")) &&
                    "bg-graydark dark:bg-meta-4"
                  }`}
                >
                  <RiDashboard2Line size={"1.5rem"} />
                  Dashboard
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/update-landing-page"
                  className={`group relative flex items-center gap-2 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                    pathname.includes("update-hero") &&
                    "bg-graydark dark:bg-meta-4"
                  }`}
                >
                  <TbHomeEdit size={"1.5rem"} />
                  Update Landing Page
                </NavLink>
              </li>
              
              <li>
                <NavLink
                  to="/forms-submitted"
                  className={`group relative flex items-center gap-2 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                    pathname.includes("contact-list") &&
                    "bg-graydark dark:bg-meta-4"
                  }`}
                >
                  <RiContactsBook3Line size={"1.5rem"} />
                  Forms Submitted
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blog-list"
                  className={`group relative flex items-center gap-2 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                    pathname.includes("contact-list") &&
                    "bg-graydark dark:bg-meta-4"
                  }`}
                >
                  <CiBoxList size={"1.5rem"} />
                  BLog List
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/create-blog"
                  className={`group relative flex items-center gap-2 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                    pathname.includes("contact-list") &&
                    "bg-graydark dark:bg-meta-4"
                  }`}
                >
                  <TbBrandBlogger size={"1.5rem"} />
                  Create Blog
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings"
                  className={`group relative flex items-center gap-2 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                    pathname.includes("update-about") &&
                    "bg-graydark dark:bg-meta-4"
                  }`}
                >
                  <IoSettingsOutline size={"1.3rem"} />
                  Settings
                </NavLink>
              </li>
              <li>
                <button
                  onClick={logout}
                  className={`group relative flex items-center gap-2 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                    pathname.includes("contact-list") &&
                    "bg-graydark dark:bg-meta-4"
                  }`}
                >
                  <IoLogOutOutline  size={"1.5rem"} />
                  LogOut
                </button>
              </li>
            </ul>
          </div>
        </nav>
          </div>
        <div>
          <p className="text-white text-center">Version 1.0</p>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
