import React, { useState } from "react";
import { MdAdd, MdDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";
import config from "../../../Config";

const UpdatePortfolio = ({portfolioData,handleChange,addNewProject,deleteProject}) => {
 


  return (
    <div className="flex">
      <div
        className="flex w-2/3 p-6 bg-gray-800 flex-col items-center justify-center gap-5"
        id="services"
      >
        <span className="text-blue-400 mb-1 text-sm md:text-base">
          Portfolio
        </span>
        <h3 className="text-2xl md:text-4xl text-center text-white">
          {portfolioData.portfolioHead}
        </h3>

        <div className="flex flex-wrap mt-20 justify-around w-[100%]   h-[50vh] gap-2">
          {portfolioData.projects.map((item, index) => (
            <Link
              target="_blank"
              to={item.projectLink}
              key={index}
              className="w-[48%] h-[30%]  md:w-[32.7%] md:h-[49%] overflow-hidden bg-gradient-to-tr cursor-pointer relative from-[#1e88e9] to-[#72d5f4]"
            >
              <p className="text-xl text-white text-center absolute top-[48%] right-[38%] object-cover  z-[0]">
                {item.projectName}
              </p>
              <img
                src={typeof item.img === 'string'? `${config.IMG_URL}/${item.img}` : " " }
                alt="something"
                className="hover:opacity-0  w-full h-full relative  z-10 "
              />
            </Link>
          ))}
        </div>
      </div>

      <div className=" w-1/3 bg-white shadow-default ">
        <div className="border-b border-stroke py-4 px-6 bg-black ">
          <h3 className="font-medium text-xl text-white">Section-4</h3>
        </div>
        <form action="#">
          <div className="p-6">
            <div className="mb-4">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Portfolio Heading
              </label>
              <input
                type="text"
                value={portfolioData.portfolioHead}
                onChange={handleChange}
                name="portfolioHead"
                placeholder="Enter the Heading for Portfolio"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            {portfolioData.projects.map((item,index)=>(
              <div key={index} className="flex flex-wrap border rounded-lg p-2 pt-4 relative mb-2 w-full justify-between">
              <div className="mb-1 w-[48%]">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Project Name
              </label>
              <input
                type="text"
                value={item.projectName}
                onChange={(e)=>handleChange(e,index)}
                name="projectName"
                placeholder="Enter Name of your Project"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            <div className="mb-1 w-[48%]">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Project Image
              </label>
              <input
                type="file"
                // value={item.projectImage}
                onChange={(e)=>handleChange(e,index)}
                accept="image/*"
                name="img"
                placeholder="Choose Image"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            <div className="mb-4 w-full">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Project Link
              </label>
              <input
                type="text"
                value={item.projectLink}
                onChange={(e)=>handleChange(e,index)}
                name="projectLink"
                placeholder="Enter the Project Link"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            <div className="absolute top-1 right-4 cursor-pointer" onClick={()=>deleteProject(index)}>
                  <MdDeleteForever  className='text-red-700 text-xl'/>
                  </div>
              </div>
            ))}
            <div className="w-full flex justify-end">
            <button
                type="button"
                onClick={addNewProject}
                className="flex w-fit items-center gap-2 my-3 justify-center rounded bg-blue-400 text-base text-white p-3 font-medium hover:bg-blue-600"
              >
                <MdAdd size={'1.7rem'} />
                Add New Skill
              </button>
              </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePortfolio;
