import React, { useState } from "react";
import FooterSetting from "./FooterSetting";
import SiteSettings from "./SiteSettings";
import axios from "axios";
import config from "../../../Config";
import { toast } from "react-toastify";

const Settings = () => {
  const token = localStorage.getItem("token");
  const [pass, setPass] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handlePassChange = (e) => {
    const { name, value } = e.target;
    setPass((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleNewPassClick = async () => {
    try {
      const res = await axios.put(`${config.BASE_URL}/profile/password`, pass, {
        headers: { "x-auth-token": `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("Password changed");
        setPass({
          oldPassword: "",
          newPassword: "",
        });
      }
    } catch (error) {
      if (error.response.status === 400) toast.error("Old Password is Wrong");
    }
  };

  return (
    <div className="p-3 ">
      <SiteSettings />

      <FooterSetting />

      <div className=" w-full bg-white shadow-default mb-16">
        <div className="border-b border-stroke bg-black py-4 px-6 ">
          <h3 className="font-medium text-white  text-xl">Password Settings</h3>
        </div>
        <form action="#" className="flex flex-col items-end">
          <div className="p-6 flex w-full flex-wrap gap-5 justify-between">
            <div className="mb-4 w-[47%]">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Create New Password
              </label>
              <input
                type="text"
                name="newPassword"
                value={pass.newPassword}
                onChange={handlePassChange}
                placeholder="Enter New Password"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
            <div className="mb-4 w-[47%]">
              <label className="mb-2 block text-base font-bold text-gray-800 dark:text-white">
                Confirm Old Password
              </label>
              <input
                type="text"
                onChange={handlePassChange}
                value={pass.oldPassword}
                name="oldPassword"
                placeholder="Confirm Old Password"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>
          </div>
          <button
            type="button"
            onClick={handleNewPassClick}
            className="flex w-fit h-fit mr-7 px-7 justify-center rounded bg-lime-400 text-base text-white p-3 font-medium hover:bg-lime-600"
          >
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default Settings;
