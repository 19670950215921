import React, { useEffect, useState } from "react";
import UpdateHero from "./UpdateHero";
import UpdateAbout from "./UpdateAbout";
import UpdateExperience from "./UpdateExperience";
import UpdateProjects from "./UpdateProjects";
import { GrDocumentUpdate } from "react-icons/gr";
import axios from "axios";
import config from "../../../Config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Template2Update = () => {
  const [templateData, setTemplateData] = useState(null);
  const [heroImgfile, setHeroImgFile] = useState(null);
  const [aboutImgfile, setAboutImgFile] = useState(null);
  const token = localStorage.getItem("token");

  // -----------------------------------------Fetch Data------------------------------------------------------------------------->|
  const fetchTemplate2 = async () => {
    try {
      const res = await axios.get(
        `${config.BASE_URL}/template2/66b059a0e4268d5041e2f00d`
      );
      setTemplateData(res.data);
      
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchTemplate2();
  }, []);

  //-------------------------------------------Upload Data------------------------------------------------------------------------->|
  const handleUpload = async () => {
    try {
      const formData = new FormData();

      // Append each file to the formData

    {heroImgfile &&  formData.append("heroImg", heroImgfile);}
     {aboutImgfile && formData.append("aboutImg", aboutImgfile);}

      // Append the rest of templateData as JSON
      formData.append("hero", JSON.stringify(templateData.hero));
      formData.append("about", JSON.stringify(templateData.about));
      formData.append("experience", JSON.stringify(templateData.experience));
      formData.append("projects", JSON.stringify(templateData.projects));

      

      const res = await axios.put(
        `${config.BASE_URL}/template2/66b059a0e4268d5041e2f00d`,
        formData, // FormData object as the request body
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file uploads
            "x-auth-token": `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        fetchTemplate2();
        toast.success("Data Updated!");
      } else {
        toast.error("Token Expired, Login again!");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //---------------------------------------------------Hero Changes----------------------------------------------------------------->|

  const handleHeroChange = (e) => {
    const { name, value } = e.target;
    if (name === "heroImg" && e.target.files) {
      try {
        const file = e.target.files[0];
        setHeroImgFile(file);
        setTemplateData((prevdata) => ({
          ...prevdata,
          hero: { ...prevdata.hero, [e.target.name]: file },
        }));
      } catch (e) {
        console.log(e.message);
      }
    } else if (name === "roles") {
      const array = value
        .split(",")
        .map((item) => item.trim()) // Trim any whitespace
        .filter((item) => item);
      setTemplateData((prevData) => ({
        ...prevData,
        hero: {
          ...prevData.hero,
          [name]: array,
        },
      }));
    } else {
      setTemplateData((prevData) => ({
        ...prevData,
        hero: {
          ...prevData.hero,
          [name]: value,
        },
      }));
    }
  };

  // ----------------------------------------------------About Changes --------------------------------------------------------------->|

  const handleaboutChange = (e) => {
    const { name, value } = e.target;
    if (name === "aboutImg" && e.target.files) {
      try {
        const file = e.target.files[0];
        
        setAboutImgFile(file);
        setTemplateData((prevdata) => ({
          ...prevdata,
          about: { ...prevdata.about, [e.target.name]: file },
        })); // Set the data URL as the image source

        const imgURL = URL.createObjectURL(file);
      } catch (e) {
        console.log(e.message);
      }
    } else if (name === "aboutHead") {
      const newValue = value.replace(
        /<c>(.*?)<\/c>/g,
        '<span class="text-[#25FF00]">$1</span>'
      );
      setTemplateData((prevData) => ({
        ...prevData,
        about: {
          ...prevData.about,
          [name]: newValue,
        },
      }));
    } else {
      setTemplateData((prevData) => ({
        ...prevData,
        about: {
          ...prevData.about,
          [name]: value,
        },
      }));
    }
  };

  //-------------------------------------------------------Experience Changes---------------------------------------------------------------->|
  const handleExperienceChange = (event, index) => {
    const { name, value } = event.target;

    // Create a copy of the current state
    const updatedExperience = [...templateData.experience];

    // Update the specific item in the array based on index
    updatedExperience[index] = {
      ...updatedExperience[index],
      [name]: value,
    };

    // Update the state with the modified array
    setTemplateData((prevData) => ({
      ...prevData,
      experience: updatedExperience,
    }));
  };

  const addExperience = () => {
    setTemplateData((prevData) => ({
      ...prevData,
      experience: [
        {
          company: "",
          startYear: 2024,
          endYear: 0,
          role: "",
          desc: "",
        },
        ...prevData.experience,
      ],
    }));
  };
  const deleteExperience = (index) => {
    setTemplateData((prevData) => {
      // Create a copy of the previous state's experience array
      const updatedExperience = [...prevData.experience];
      // Remove the experience entry at the specified index
      updatedExperience.splice(index, 1);
      // Return the updated state object
      return {
        ...prevData,
        experience: updatedExperience,
      };
    });
  };

  //----------------------------------------------------------------------------Projects Change ------------------------------------------->|
  const handleProjectChange = (event, index) => {
    const { name, value } = event.target;

    // Create a copy of the current state
    const updatedProject = [...templateData.projects];

    // Update the specific item in the array based on index
    updatedProject[index] = {
      ...updatedProject[index],
      [name]: value,
    };

    // Update the state with the modified array
    setTemplateData((prevData) => ({
      ...prevData,
      projects: updatedProject,
    }));
  };

  const addProjects = () => {
    setTemplateData((prevData) => ({
      ...prevData,
      projects: [
        ...prevData.projects,
        {
          projectName: "",
          projectLink: "",
          projectDesc: "",
        },
      ],
    }));
  };
  const deleteProject = (index) => {
    setTemplateData((prevData) => {
      // Create a copy of the previous state's experience array
      const updatedProject = [...prevData.projects];
      // Remove the experience entry at the specified index
      updatedProject.splice(index, 1);
      // Return the updated state object
      return {
        ...prevData,
        projects: updatedProject,
      };
    });
  };

  return (
    <>
      {templateData && (
        <>
          {" "}
          <UpdateHero
            data={templateData.hero}
            handleChange={handleHeroChange}
          />
          <UpdateAbout
            aboutData={templateData.about}
            handleaboutChange={handleaboutChange}
          />
          <UpdateExperience
            xpData={templateData.experience}
            handleChange={handleExperienceChange}
            addNew={addExperience}
            deleteExperience={deleteExperience}
          />
          <UpdateProjects
            projectsData={templateData.projects}
            handleChange={handleProjectChange}
            addNew={addProjects}
            deleteProject={deleteProject}
          />
          <div className="w-full flex justify-end p-5 bg-gray-950">
            <button
              type="button"
              onClick={handleUpload}
              className="flex w-fit gap-2 items-center mx-10 px-12 justify-center  rounded bg-lime-500 text-base text-white p-3 font-medium hover:bg-lime-700"
            >
              <GrDocumentUpdate size={"1.3rem"} /> Update
            </button>
          </div>{" "}
        </>
      )}
    </>
  );
};

export default Template2Update;
